.container hr {
  /* border-top: 0.5px solid rgba(0, 0, 0, 0.2) !important; */
  height: 0.5px;
  background: rgba(0, 0, 0, 0.2) !important;
  border: none;
}

.center {
  text-align: center;
}
